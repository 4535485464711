.anreise-v1 {
	margin-top: var(--page-margin-top);

	.anreise-content-boxes {
		.anreise-box {
			display: flex;
			flex-wrap: wrap;
			background-color: var(--contact-sidebar-bg-color);
			padding: 15px;
			margin: 0 0 30px 0;

			.box-image {
				width: 25%;

				@media (max-width: 767px) {
					width: 100%;
				}

				img {
					max-width: 100%;
				}
			}

			.box-text {
				width: 75%;
				padding: 0 0 0 30px;
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
				position: relative;

				@media (max-width: 767px) {
					width: 100%;
					padding: 30px 0 0 0px;
				}

				h4 {
					width: 100%;
				}

				h6 {
					display: flex;
					position: absolute;
					bottom: 0;
					right: 0;
					text-transform: uppercase;

					@media (max-width: 1024px) {
						position: relative;
						text-align: right;
						justify-content: end;
					}
				}
			}
		}

	}
}