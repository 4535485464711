.parallax-v1 {
	position: relative;
	background-image: url(RESOURCE/img/parallax5.jpg);


	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@media (max-width:992px) {
		background-attachment: scroll;
	}

	.parallax-box {
		background-color: var(--color-secondary);
		padding: 20px;
		min-height: 400px;

		.title {
			color: var(--color-white);

		}

		.text {
			color: var(--color-white);
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 100%;

			p {
				width: 100%;

				&:last-child {
					margin-bottom: 0;
				}

				a {
					color: #fff;
					text-decoration: underline !important;
				}
			}
		}
	}

}