.search-unit-v1 {

	.unit-row {
		padding: 15px;
		background-color: #fff;
	}

	.img-col {
		position: relative;
		overflow: hidden;
		margin-left: 15px;
		height: 195px;
		overflow: hidden;

		@media (max-width: 1200px) {
			margin-right: 15px;
		}

		img {
			-o-object-fit: cover;
			object-fit: cover;
			-o-object-position: center center;
			object-position: center center;
			height: 100%;
			width: 100%;
		}
	}

	.info-col {
		@media (max-width: 1200px) {
			padding-top: 15px;
		}
	}

	.tick-li {

		li {
			font-size: var(--font-size-md);
			padding-left: 20px;
			padding-right: 20px;
			position: relative;
			display: inline-block;

			&:before {
				content: "\f00c";
				font-family: "Font Awesome 5 Free";
				-webkit-font-smoothing: antialiased;
				font-weight: 600;
				display: inline-block;
				position: absolute;
				left: 0;
				top: 0;
				color: var(--color-primary);
			}
		}

	}

	.fav-icon {
		position: absolute;
		right: 10px;
		top: 10px;
		color: #fff;
		cursor: pointer;
	}

	.properties-row {}

	.unit-properties {

		li {
			padding-right: 20px;
			display: inline-block;

			.fa {
				width: 30px;

				.fa-stack {
					margin-left: -10px;
					line-height: 1.4em;
				}
			}
		}
	}

	.unit-price-col {
		display: table;

		.unit-act-row {
			display: table-cell;
			vertical-align: bottom;
		}
	}

	.rent {
		text-align: center;
	}

	.small {
		font-size: var(--font-size-md);

		@media (min-width: 1201px) {
			&:after {
				content: "\A";
			}
		}
	}

	.main-price {
		font-weight: 600;
		text-decoration: line-through;
	}

	.discount-price {
		font-weight: 600;

		.reducedprice {
			color: var(--color-red);
		}
	}

	.prices {
		text-align: center;

		@media (max-width: 1200px) {
			text-align: right;
		}

	}

	.offer-box {
		padding-top: 15px;
		padding-bottom: 15px;
		position: relative;
		display: flex;
		margin: 0;
		align-items: center;
		flex-wrap: wrap;
		background-color: #fff;
		color: var(--color-black);
		border-top: 1px dashed var(--color-grey-normal);
		border-bottom: 1px dashed var(--color-grey-normal);
		margin-top: 15px;

		@media (max-width:1200px) {
			display: block
		}

		.offer-left {
			background: var(--color-secondary-light);
			color: var(--color-black);
			padding: 10px 15px;
			border: 1px dashed var(--color-primary);
			font-size: 16px;
			text-transform: uppercase;
			display: flex;
			align-items: center;
			margin-right: 15px;

			@media (max-width:1200px) {
				display: block;
				text-align: center;
				margin-right: 0;
				margin-bottom: 15px
			}
		}

		ul {
			padding: 0;
			list-style: none;
			margin: 0;

			li {
				margin: 0 0 5px;
				line-height: 14px;
				font-size: 14px
			}
		}
	}

	.alternatives {
		margin-top: 15px;
		padding: 0 5px;
		background-color: var(--color-grey-light);

		.altheading {
			font-weight: 600;
			color: var(--color-primary);

		}



		a {
			color: var(--font-color-main);
			text-decoration: underline !important;

			&:hover {
				text-decoration: none !important;
				color: var(--color-red);
			}


		}

		.fa {
			color: var(--color-primary);
			font-size: 12px;
			line-height: 16px;
		}

		.invalid {
			text-decoration: line-through;
		}

		.special {
			color: var(--color-primary);
			font-weight: 600;
		}

	}



}