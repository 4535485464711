@media print {

	*,
	*:before,
	enter code here *:after {
		color: #000 !important;
		text-shadow: none !important;
		background: transparent !important;
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
		margin: 0;
		padding: 0
	}

	a[href]:after {
		content: none !important;
	}

	a,
	a:visited {
		text-decoration: underline
	}

	a[href]:after {
		content: " ("attr(href) ")"
	}

	abbr[title]:after {
		content: " ("attr(title) ")"
	}

	a[href^="#"]:after,
	a[href^="javascript:"]:after {
		content: ""
	}

	.header-print {
		left: 0;
		position: fixed;
		top: ;
		display: table-header-group;
	}

	.unit-view .showLess .unit-widgt-bl {
		display: block !important;
	}

	.show-more,
	.owl-nav {
		display: none;
	}

	.v-img-gallery {
		display: block !important;

		a {
			float: left;

			.img-thumbnail {
				width: 20% !important;
				margin: 10px;


			}
		}
	}


	p {
		font-size: 13px
	}

	h3 {
		font-size: 16px
	}


	.hidden-print {
		display: none;
		visibility: hidden
	}

	.v-img-gallery {
		margin: 20px 0;

		a {
			&:nth-child(n + 6) {
				display: none;
			}
		}


	}



	/*ul {
		li:nth-of-type(1n+4) {
			display: none;
		}
	}*/

	@page {
		margin: 2mm;
	}

	.print-gap {
		margin-top: 80px;
	}

	.unit-view {
		padding-top: 10px
	}

	.page-brake {
		page-break-after: always
	}

	.text-blue {
		color: #0060a1 !important
	}

	.txt-orange {
		color: #fa590c !important
	}

	.text-grey {
		color: #797979 !important
	}
}

.unit-section {
	margin-bottom: var(--unit-section-mb);

	.modal-dialog {
		max-width: 900px !important;

		@media (max-width: 767px) {
			max-width: 100% !important;
			align-items: unset !important;
			padding: 30px 15px;
			margin-top: 0;
			margin-bottom: 0;
			height: 100%;
		}

		@media all and (min-width: 768px) and (max-width: 991px) {
			max-width: 700px !important;
		}

		.modal-body {
			height: 80vh;
			overflow: auto;

			@media (max-width: 767px) {
				height: 70vh;
			}
		}
	}

	#AktuelleModal {
		.modal-body {
			padding: 40px 40px 10px 40px;
		}
	}
}

.unitRequest {
	.conact-btn {
		margin-top: 15px;
	}

	.modal-dialog {
		max-width: 900px !important;

		@media (max-width: 767px) {
			max-width: 100% !important;
			align-items: unset !important;
			padding: 30px 15px;
			margin-top: 0;
			margin-bottom: 0;
			height: 100%;
		}

		@media all and (min-width: 768px) and (max-width: 991px) {
			align-items: unset !important;
			max-width: 700px !important;
		}

		.modal-content {
			height: 67vh;

			@media (max-width: 767px) {
				height: 100%;
			}

			@media all and (min-width: 768px) and (max-width: 991px) {
				height: 65vh;
			}

			@media all and (min-width: 992px) and (max-width: 1500px) {
				height: 72vh;
			}
		}

		.modal-body {
			height: 100%;
			overflow: auto;

			@media (max-width: 767px) {
				height: 60vh;
			}
		}
	}
}

.unit-pricelist-v1 {
	.widget-block {
		@media (max-width:767px) {
			overflow-y: scroll;
		}
	}
}

.unit-map {
	width: 100%;
	height: var(--unit-map-height);
	position: relative;
}



@media (max-width: 992px) {
	.unit-view {
		.owl-carousel-unit {
			.img-thumbnail {
				height: 500px;
				object-fit: cover;
			}
		}
	}
}

@media (max-width: 767px) {
	.unit-view {
		.owl-carousel-unit {
			.img-thumbnail {
				height: 350px;
				object-fit: cover;
			}
		}
	}
}