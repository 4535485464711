.unit-popup-buttons {
	background-color: var(--widget-header-bg-color);
	padding: 20px 15px;


	button.btn-primary {
		width: 100%;
		background-color: #87becf;
		border-color: #87becf;

	}

	@media (max-width: 1200px) {
		margin-bottom: 0.5rem !important;
		background: transparent;
		padding: 0;
		display: block !important;
	}

	@media all and (min-width: 992px) and (max-width: 1200px) {
		margin-bottom: 30px !important;
	}

}

.unit-booking-v1 {

	.search-bl {
		position: relative;

		.fa {
			position: absolute;
			right: 0;
			top: 15px;
			color: var(--color-grey-dark);
		}
	}

	.form-control {
		border: none;
		border-bottom: 1px solid #e6e6e6;
		text-align: left;
		margin-bottom: 15px;
		padding: 10px 0;
		background-color: var(--color-white);
		font-size: var(--font-size-md);
	}

	.table {
		td {
			border-top: 2px solid var(--color-white);
		}

		tbody {
			border-top: 2px solid var(--color-white);
		}
	}

	.optionals {
		margin-top: 20px;
	}

	.optionals-title {
		font-size: var(--h4-font-size);
		font-weight: var(--h4-font-weight);
		padding-left: 10px;
	}

	.consumptioncosts-title {
		font-size: var(--h5-font-size);
		font-weight: var(--h5-font-weight);
		padding-left: 10px;
	}

	.button-row {
		text-align: right;
		margin-top: 20px;

	}

	.subrow {
		margin-top: 20px;
	}

	.btn-option {
		margin-top: 10px;
	}
}